.text-animate {
    height: 60vh;
    width: 100%;
    font-size: 30px;
    padding-top: 6rem;
    padding-left: 2rem;
    margin-bottom: 5rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main-animate {
    line-height: 1.2;
}

h1 {
    font-size: 5rem;
    margin: 0;
}

.tagline {
    font-size: 2.4rem;
    margin: 1rem 0;
}

.para {
    width: 50%;
    line-height: 1.5;
    font-size: 1.25rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .text-animate {
        padding-top: 5rem;
        padding-left: 1rem;
        font-size: 24px;
    }

    h1 {
        font-size: 4rem;
    }

    .tagline {
        font-size: 2rem;
    }

    .para {
        width: 70%;
        font-size: 3rem;
    }
}

@media (max-width: 480px) {
    .text-animate {
        padding: 0;
        margin-top:6rem;
    }

    h1 {
        margin-left: .5rem;
        font-size: 2.5rem;
    }

    .tagline {
        margin-left: .5rem;
        margin-top: -.1rem;
        font-size: 1.3rem;
    }

    .para {
        margin-left: .5rem;
        margin-top: 1rem;
        width: 90%;
        font-size: 1rem;
        line-height: 1.4;
    }
}

/* General Styles */
.background-video::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: rgba(0,0,0,1);
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.86));
    z-index: -1;
}

.background-image {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .background-video {
        display: none;
    }

    .background-image {
        display: block;
    }
}