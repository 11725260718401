/* Review Section Styling */
.review-section {
  padding: 20px;
  background-color: #f9f9f9;
}

.review-slider {
  margin: 20px 0;
}

/* Review Card Styling */
.review-card {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
  max-width: 100%; /* Ensure cards don't overflow */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.review-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.review-card h3 {
  margin: 10px 0;
}

.review-card .position {
  font-style: italic;
  color: #555;
}

.review-card .phone,
.review-card .review {
  font-size: 14px;
  color: #333;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .review-card {
    padding: 15px;
  }
  .review-card img {
    width: 90px;
    height: 90px;
  }
  .review-card h3 {
    margin: 8px 0;
  }
  .review-card .phone,
  .review-card .review {
    font-size: 13px;
  }
}

@media (max-width: 992px) {
  .review-card {
    padding: 10px;
  }
  .review-card img {
    width: 80px;
    height: 80px;
  }
  .review-card h3 {
    margin: 6px 0;
  }
  .review-card .phone,
  .review-card .review {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .review-card {
    padding: 8px;
  }
  .review-card img {
    width: 70px;
    height: 70px;
  }
  .review-card h3 {
    margin: 5px 0;
  }
  .review-card .phone,
  .review-card .review {
    font-size: 11px;
  }
}

@media (max-width: 576px) {
  .review-section {
    padding: 10px;
  }
  .review-card {
    padding: 1rem;
  }
  .review-card img {
    width: 60px;
    height: 60px;
  }
  .review-card h3 {
    margin: 4px 0;
  }
  .review-card .phone,
  .review-card .review {
    font-size: 10px;
  }
}
