/* Global styles */
.about-us-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: #d80650;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.background-image {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5); /* Optional: To make text more readable */
}

.animated-text {
  animation: fadeIn 2s ease-in-out;
}

.animated-text + .animated-text {
  animation: fadeIn 2s ease-in-out 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

h1.animated-text {
  font-size: 3em;
  margin-bottom: 20px;
}

p.animated-text {
  font-size: 1.5em;
  max-width: 800px;
}

/* Media Queries for responsive design */

/* For small screens */
@media (max-width: 600px) {
  .background-video {
    display: none;
  }

  .background-image {
    display: block;
  }

  .overlay {
    padding: 10px;
  }

  h1.animated-text {
    font-size: 2em;
    margin-bottom: 15px;
  }

  p.animated-text {
    font-size: 1.2em;
    max-width: 90%;
  }
}

/* For medium screens */
@media (min-width: 601px) and (max-width: 768px) {
  h1.animated-text {
    font-size: 2.5em;
  }

  p.animated-text {
    font-size: 1.3em;
  }
}
