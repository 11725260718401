/* Add these styles for the custom solutions section */
.custom-solutions-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px 20px; /* Adjust padding as needed */
}

.left-side, .right-side {
  flex: 1;
  padding: 20px;
  color: #fff; /* Text color for contrast */
}

.left-side {
  background-color: #3d5a80 ; /* Left side background color */
}

.right-side {
  background-color: #98c1d9; /* Right side background color */
}

.custom-solutions-section h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.custom-solutions-section p {
  font-size: 16px;
  line-height: 1.6;
}

/* Animation effect (example) */
.custom-solutions-section {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.custom-solutions-section.animate {
  opacity: 1;
  transform: translateY(0);
}

.feature-cards-container {
  display: flex;
  justify-content: space-around;
  margin: 20px;
  padding: 20px;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.SDIMG{
  background-size: cover;
  height: 130vh;
  width: 100vw;
  z-index:-10;
  margin-top:-4rem;
}

.feature-card {
  flex: 1;
  padding: 20px;
  margin: 10px;
  background-color: #fff;
  color: #000;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.feature-card h3 {
  margin-bottom: 10px;
}

.feature-card p {
  margin: 0;
}

.feature-card:hover {
  background-color: #e0fbfc;
  color: #000000;
  cursor: pointer;
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feature-card:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 8px;
  border: 1px solid transparent;
  transition: border-color 0.3s ease;
}

.feature-card:hover:after {
  border-color: #fff;
}

.feature-cards-container .feature-card:first-child {
  margin-left: 0;
}

.feature-cards-container .feature-card:last-child {
  margin-right: 0;
}
.info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  padding: 20px;
  background-color: #070707;
  border-radius: 8px;
  height: 500px;
}

.info-text {
  flex: 1;
  margin-right: 20px;
  color: #f8f6f6;
}

.info-text h2 {
  margin-bottom: 20px;
}

.points-list {
  list-style: none;
  padding: 0;
}

.point-item {
  margin-bottom: 10px;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

.point-item .tick-icon {
  margin-right: 10px;
  color: #D80650;
  transition: color 0.3s ease;
}

.point-item:hover {
  color: #D80650;
}

.point-item:hover .tick-icon {
  color: #faf6f7;
}

.info-image {
  flex: 1;
}

.info-image img {
  width: 100%;
  border-radius: 8px;
}
.black-container {
  background-color: #f5f3f3;
  color: #050505;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.black-container:hover {
  background-color: #D80650;
  color: #fff;
  cursor: pointer;
}

.tick-icon {
  margin-right: 5px;
  font-size: 1.2em;
}


.points-container {
  display: flex;
}

.points-left, .points-right {
  flex: 1;
}

.points-left {
  margin-right: 10px;
}

.points-right {
  margin-left: 10px;
}

.scroll-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px; /* Adjust as needed */
}

.unit-container {
  display: flex;
  width: 100%;
  height: 400px; /* Adjust height as needed */
  margin-bottom: 30px;
  border-radius: 8px; /* Adjust margin as needed */
}

.half-container {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.left-content {
  background-color: #f0f0f0; /* Left side background color */
}

.right-image.transparent-bg {
  background-color: rgba(255, 255, 255, 0.5); /* White background with 50% transparency */
}/* Value between 0 (fully transparent) and 1 (fully opaque) */


.content {
  padding: 20px;
  text-align: center;
}

.right-image img {
  
  max-width: 100%;
  max-height: 100%;
}


@media (max-width: 768px) {
  .custom-solutions-section {
    flex-direction: column;
    padding: 40px 10px;
  }

  .left-side, .right-side {
    padding: 10px;
    text-align: center;
  }

  .feature-cards-container {
    flex-direction: column;
    padding: 10px;
  }

  .info-container {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .info-text, .info-image {
    width: 100%;
    margin-bottom: 20px;
  }

  .points-container {
    flex-direction: column;
  }

  .points-left, .points-right {
    margin: 0;
  }

  .scroll-container {
    flex-direction: column;
  }

  .unit-container {
    height: auto;
    flex-direction: column;
  }

  .right-image {
    height: 250px; /* Adjust height for mobile */
  }

  .feature-card {
    margin: 5px;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .SDIMG{
    background-size: cover;
    height: 100%;
    width: 100%;
    margin-top: 0rem;
  }
  .custom-solutions-section {
    flex-direction: column; /* Stack the sections vertically */
    padding: 20px; /* Add padding from the screen edges */
    gap: 20px; /* Add gap between left and right sides */
  }

  .left-side, .right-side {
    padding: 10px;
    text-align: center;
    margin-bottom: 10px; /* Add margin between sections */
  }


  .feature-card {
    padding: 10px;
    margin: 5px 0;
  }

  .info-container {
    height: auto;
  }
  .left-content{
    width: auto;
  }
  .right-image {
    height: 200px; /* Adjust height for smaller screens */
    width: auto;
  }
  .scroll-container {
    flex-direction: column;
    padding: 0 20px; /* Add horizontal padding */
  }  
  .points-container {
    display: flex;
    flex-direction: column; /* Stack points vertically */
    align-items: center; /* Center the points horizontally */
    justify-content: center; /* Center the points vertically */
    width: 100%;
    text-align: center;
    margin-left: 0.7rem;
  }


  /* Ensure left and right points take full width on phones */
  .points-left, .points-right {
    width: 100%;
    text-align: center; /* Center the text inside the containers */
  }

}