/* Reset default margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Navbar styles */
.navbar {
  backdrop-filter: blur(1rem);
  background:rgba(255, 255, 255, 0.5);
  padding: 0.75rem 1.5rem;
  position: sticky;
  color: #000;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-main{
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-main span{
  margin-left: -1rem;
  font-weight: 800;
  color: rgba(79, 4, 87, 0.712);
}

.navbar-logo {
  display: flex;
  align-items: center;
  font-weight: bold;
  transition: color 0.3s ease;
}

.logo-main {
  font-size: 1.8em;
  text-decoration: none;
  color: #f0f0f0;
}

.navbar-logo img {
  height: 50px;
  margin-right: 15px;
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 2rem;
  align-items: center;
  margin: 0;
  padding: 0;
}

.navbar-menu li {
  font-size: 1.2rem;
}

.navbar-item {
  position: relative;
  font-size: 1rem;
}

.navbar-item a{
  font-weight: 600;
  color: #000;
}

.navbar-link {
  color: #000;
  border: none;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-link:hover,
.navbar-link.active {
  background-color: #051a8d;
  color: #ffffff;
}

.navbar-link:hover::after,
.navbar-link.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #9eadf0;
}

.navbar-toggle {
  display: none;
  font-size: 2rem;
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
}

@media (max-width: 768px) {

  .navbar-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .logo-main span{
    margin-left: -1rem;
    color: rgb(79, 4, 87);
  }

  .logo-main{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    position: absolute;
    top: 100%;
    left: 0;
    background: #050A44;
    border-top: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .navbar-menu.navbar-menu-open {
    display: flex;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-item {
    width: 100%;
    margin: 0;
  }

  .navbar-link {
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
  }

  .dropdown-menu {
    display: none;
    flex-direction: column;
    background-color: white;
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 1rem;
    z-index: 10;
    min-width: 100px;
  }

  .navbar-dropdown:hover .dropdown-menu {
    display: flex;
  }

  .link-dropdown-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    list-style-type: none;
    left: 50%;
    top: 0;
    padding: 0;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
    min-width: 250px;
  }

  .link-dropdown-menu li {
    padding: 5px
  }

  .link-dropdown:hover .link-dropdown-menu {
    display: flex;
  }

  .link-dropdown {
    position: relative;
    font-size: 13px;
  }

  .link-dropdown a {
    font-size: 13px;
  }

  .dropdown-menu li{
    border:none;
    padding: 0px;
  }
}

@media (max-width: 480px) {
  .navbar-logo img {
    width: 40px;
    height: 40px;
  }
  .navbar-item a{
    color: #fff;
  }
  .navbar-logo a {
    font-size: 1.3rem;
    margin-left: -1rem;
  }

  .navbar-menu {
    gap: 0;
  }

  .navbar-menu li {
    gap: 0;
    font-size: 15px;
    padding: .5rem;
    border: 0.5px solid gray;
  }

  .navbar-link {
    font-size: 1rem;
  }

  .dropdown-menu {
    display: none;
    flex-direction: column;
    background-color: white;
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
    min-width: 100px;
  }
  .dropdown-menu li{
    border:none;
    padding: 0px;
  }

}

.navbar-dropdown-container {
  position: relative;
}

.navbar-toggle {
  font-size: 1.5rem;
}

.navbar-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dropdown-menu {
  display: none;
  flex-direction: column;
  background-color: white;
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 100px;
}

.dropdown-menu li{
  border:none;
  padding: 0px;
}

.link-dropdown{
  border: 1px solid gray;
}

.link-dropdown{
  width: 13rem;
  padding: 5px;
}

.navbar-dropdown:hover .dropdown-menu {
  display: flex;
}

.link-dropdown-menu {
  display: none;
  flex-direction: column;
  position: absolute;
  list-style-type: none;
  right: 100%;
  top: 0;
  padding: 0;
  background-color: white;
  border: 1px solid hsl(0, 0%, 80%);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 250px;
}

.link-dropdown-menu li {
  padding: 10px
}

.link-dropdown:hover .link-dropdown-menu {
  display: flex;
}

.link-dropdown {
  position: relative;
  font-size: 1rem;
}

.link-dropdown a {
  font-size: 1rem;
}

.link-dropdown .btn {
  padding: 0.5rem;
  cursor: pointer;
  text-align: left;
  width: 100%;
  background: none;
  border: none;
  color: #050A44;
}

.link-dropdown-menu a {
  color: black;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s, color 0.3s;
}

.link-dropdown-menu a:hover {
  color: blue;
}

.dropdown-item:hover {
  background-color: #051a8d;
}  