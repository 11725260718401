/* NewPage.css */
/* Import slick-carousel styles */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.new-page-container {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px;
}

.top-image {
  text-align: center;
  width: 100vw;
  height: 100vh;
}

.ad-content {
  margin-top: 5rem;
}

.main-heading {
  font-size: 28px;
  margin-bottom: 10px;
}

.main-paragraph {
  color: #666;
  line-height: 1.6;
}

.flowchart-container {
  margin-top: 6rem;
}

.flowchart-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.flowchart-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.flowchart-item {
  flex: 1;
  text-align: left;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.flowchart-item h3 {
  font-size: 18px;
  margin-bottom: 8px;
}

.logo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.logo-heading {
  font-size: 24px;
  margin-top: 5rem;
  width: 100%;
}

.logo-item {
  width: 150px;
  margin: 20px;
  text-align: center;
}

.logo-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  transition: transform 0.9s ease;
}

.logo-item:hover .logo-img {
  transform: translateY(-30px);
  box-shadow: 20px;
}

.slider-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-container {
  width: 100%;
  max-width: 1200px;
  margin-top: 5rem;
  background: #3d5a80;
}

.slider-heading {
  text-align: center;
  margin-bottom: 20px;
}

.slider-item {
  display: flex;
  align-items: center;
}

.Slider-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-height: 300px;
  /* Adjust as needed */
}

.slider-img img {
  margin-top: 4rem;
  width: 25vw;
  height: 60vh;
}

.slider-content {
  flex: 1;
  padding: 20px;
}

.slider-content h3 {
  margin-top: 0;
}

.slick-dots {
  bottom: -30px;
  /* Adjust if needed */
}

@media (max-width: 768px) {
  .top-image {
    height: 40vh;
    width: 50vh;
    /* Further reduced height for smaller screens */
  }
}

@media (max-width: 600px) {
  .logo-container {
    grid-template-columns: repeat(2, 1fr);
    padding: .5rem;
    font-size: 12px;
    text-transform:lowercase;
    /* Two logos per row on small screens */
  }

  .top-image {
    text-align: center;
    width: 100%;
    height: 100%;
    margin-bottom: -4rem;
  }

  .main-heading {
    text-decoration: none;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .slider-container {
    margin-top: 1rem;
  }

  .slider-img {
    display: flex;
    padding: 0;
    margin-top: -2rem;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 300px;
    /* Adjust as needed */
  }

  .slider-img img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 300px;
    /* Adjust as needed */
  }

  .slider-content {
    margin-top: 3rem;
    padding: .7rem;
  }

  .logo-item {
    margin: 10px 0;
    /* Adjust margin for better spacing */
  }
}