/* Global styles for the footer */
.footer {
  background-color: #141414;
  color: #fff;
  padding: 20px 0;
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer a:hover {
  color: lightgray; /* Optional: change color on hover */
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer h3 {
  margin-bottom: 10px;
  border-bottom: 2px solid #fff; /* Add underline */
  padding-bottom: 5px; /* Adjust spacing between text and underline */
}

.footer-divider {
  width: 80%;
  border: 0;
  height: 1px;
  background: #fff;
  margin-bottom: 20px;
}

.footer-sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
}

.footer-section {
  margin: 10px;
  flex: 1 1 calc(50% - 20px); /* Two sections per row with space between */
  text-align: center;
  padding: 10px; /* Add padding inside border */
  box-sizing: border-box; /* Include border in element's total width and height */
}



.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section li {
  margin: 5px 0;
}

.footer-social {
  margin-top: 20px;
}

.footer-social a {
  color: #fff;
  margin: 0 10px;
  font-size: 24px;
  text-decoration: none;
}

.footer-social a:hover {
  color: #7883ff;
}

/* Media Queries for responsive design */

/* Adjust layout for small screens */
@media (max-width: 600px) {

  .heading h2{
    text-decoration: none;
    font-size: 7px;
    padding: 0;
    margin: 0;
  }
  .footer-sections {
    flex-direction: row; /* Display two sections per row */
    align-items: flex-start;
  }

  .footer-section {
    flex: 1 1 calc(50% - 20px); /* Two sections per row with space between */
    margin-bottom: 20px;
  }

  .footer-social a {
    font-size: 20px;
  }
}

/* Adjust layout for medium screens */
@media (min-width: 601px) and (max-width: 768px) {
  .footer-section {
    flex: 1 1 calc(50% - 40px); /* Two sections per row with space between */
  }
}

/* Adjust layout for larger screens */
@media (min-width: 769px) {
  .footer-section {
    flex: 1 1 calc(25% - 40px); /* Four sections per row with space between */
  }
}
