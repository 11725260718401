/* Original Styles */
.WDIMG {
  background-size: cover;
  background-position: center;
  height: 130vh;
  width: 100vw;
  z-index: 10;
  margin-top: -5rem;
  position: relative;
}

.content-box {

  background-color: #98c1d9;
  padding: 20px;
  border-radius: 10px;
  margin: 20px auto; /* Center the box */
  transition: background-color 0.5s ease;
  max-width: 1200px; /* Limit width for better responsiveness */
  position: relative; /* Ensure it’s correctly positioned */
}

.content-box:hover {
  animation: hoverAnimation 2s infinite;
}

@keyframes hoverAnimation {
  0% { background-color: #365494; }
  50% { background-color: #8696ee; }
  100% { background-color: #81bafc; }
}

.points-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;
  max-width: 1200px; /* Limit width for better responsiveness */
  margin: 0 auto; /* Center the container */
}

.point-box {
  background-color: #aacdfc;
  padding: 15px;
  border-radius: 8px;
  width: 250px;
  margin: 10px;
  text-align:left;
  transition: transform 0.3s ease;
}

.point-box:hover {
  transform: scale(1.05);
}

.banner {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.banner-img {
  width: 100%;
  max-width: 1200px; /* Limit width for better responsiveness */
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .WDIMG {
    height: 80vh; /* Reduce height for smaller screens */
    margin-top: -2rem; /* Adjust top margin for smaller screens */
  }

  .content-box {
    padding: 15px;
    margin: 10px;
    max-width: 100%; /* Full width on smaller screens */
  }

  .points-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin: 0 auto; /* Center the container */
  }

  .point-box {
    width: 90%; /* Make point boxes take more width on smaller screens */
    margin: 10px 0; /* Adjust margin to fit smaller screens */
  }

  .banner {
    margin-top: 10px;
  }

  .banner-img {
    max-width: 100%; /* Ensure image fits within container */
    border-radius: 5px;
  }
}

@media (max-width: 480px) {
  .WDIMG {
    height: 60vh; /* Further reduce height for very small screens */
    margin-top: -7rem; /* Further adjust top margin */
  }

  .content-box {
    padding: 10px;
    margin: 0 auto; /* Remove side margins */
    max-width: 95%; /* Use 95% of the viewport width */
  }
  .points-container {
    padding: 5px;
    margin: 0 auto; /* Center the container */
  }

  .point-box {
    width: 100%; /* Make point boxes full width on very small screens */
    margin: 5px 0; /* Adjust margin */
  }

  .banner {
    margin-top: 5px;
  }

  .banner-img {
    max-width: 100%;
  }
}
