/* General Styles */
.Salesforce {
    position: relative;
    width: 100%;
    height: 700px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  
  .imageContainer {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  
  .overlayText {
    position: absolute;
    color: white;
    z-index: 2;
    text-align: center;
    padding: 40px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .paragraph1 {
    margin: 0 0 20px 0;
    font-size: 2.5rem;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    animation-delay: 1s;
  }
  
  .paragraph {
    margin: 0;
    font-size: 1.2rem;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    animation-delay: 1.5s;
  }
  
  .servicesSection {
    padding: 60px 20px;
    text-align: center;
    background-color: #f9f9f9;
  }
  
  .servicesHeading {
    font-size: 2.5rem;
    margin: 20px 0;
  }
  
  .servicesParagraph {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .carousel {
    margin: 40px auto;
    display: flex;
    justify-content: center;
  }
  
  .card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  }
  
  .cardImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }
  
  .cardTitle {
    font-size: 1.5rem;
    margin: 10px 0;
  }
  
  .cardParagraph {
    font-size: 1rem;
    margin: 10px 0;
  }
  
  .chooseUsSection {
    padding: 60px 20px;
    text-align: center;
    background-color: #e0f7fa;
  }
  
  .chooseUsHeading {
    font-size: 2.5rem;
    margin: 20px 0;
  }
  
  .chooseUsContent {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .chooseUsBox {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    width: calc(45% - 20px);
    box-sizing: border-box;
  }
  
  .chooseUsBoxHeading {
    font-size: 1.8rem;
    margin: 10px 0;
  }
  
  .chooseUsBoxParagraph {
    font-size: 1rem;
    margin: 10px 0;
  }
  
  @keyframes fadeInUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* React Responsive Carousel Specific Styles */
  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 0.5;
  }
  
  .carousel .control-arrow:hover {
    background: rgba(0, 0, 0, 0.2);
  }
  
  .carousel .carousel-status {
    display: none;
  }
  
  .carousel .thumbs-wrapper {
    margin: 0;
  }
  
  .carousel .thumb {
    border: none;
    box-shadow: none;
  }
  
  .carousel .carousel .slide img {
    display: block;
    margin: auto;
  }
  
  .carousel .carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .Salesforce {
      height: auto;
      flex-direction: column;
      text-align: center;
    }
  
    .overlayText {
      padding: 10px;
    }
  
    .paragraph1 {
      font-size: 1.2rem;
    }
  
    .paragraph {
      font-size: 1rem;
    }
  
    .chooseUsContent {
      flex-direction: column;
      align-items: center;
    }
  
    .chooseUsBox {
      width: 100%;
      margin: 10px 0;
    }
  
    .cardImage {
      height: 150px;
    }
  
    .carousel {
      margin: 20px 0;
      display: flex;
      justify-content: center;
      width: 100%;
      box-sizing: border-box;
    }
  
    .servicesSection {
      padding: 20px;
    }
  
    .servicesHeading {
      font-size: 2rem;
    }
  
    .servicesParagraph {
      font-size: 1rem;
    }
  }
  