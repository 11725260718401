.img {
  width: 100vw;
  height: 50vh;
}

.heading {
  margin-top: 1rem;
  text-shadow: 4px 4px rgba(0, 0, 0, 0.3);
}

.heading-2 {
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
}

.heading-3 {
  font-size: 1.5rem;
  text-align: center;
}

.insurance-para {
  text-align: center;
  padding: 0 2rem 2rem 2rem;
}

.insurance-card-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.insurance-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.insurance-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
  .insurance-card {
    flex: 1 1 calc(33.333% - 40px);
  }
}

@media (min-width: 1024px) {
  .insurance-card {
    flex: 1 1 calc(25% - 40px);
  }
}

/* CardSection.css */
.custome-card-section {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

.custome-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 300px;
}

.custome-card img {
  display: block;
  width: 100%;
  height: auto;
}

.custome-card p {
  text-align: center;
  font-size: 16px;
  color: #333;
}

.custome-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.custome-title {
  color: #ddd;
  font-weight: bold;
  text-align: center;
}


.img {
  width: 100vw;
  height: 150vh;
}

.heading {
  margin-top: 1rem;
  text-shadow: 4px 4px rgba(0, 0, 0, 0.3);
}

.heading-2 {
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
}

.heading-3 {
  font-size: 1.5rem;
  text-align: center;
}

.insurance-para {
  text-align: center;
  padding: 0 2rem 2rem 2rem;
}

.insurance-card-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.insurance-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.insurance-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
  .insurance-card {
    flex: 1 1 calc(33.333% - 40px);
  }
}

@media (min-width: 1024px) {
  .insurance-card {
    flex: 1 1 calc(25% - 40px);
  }
}

/* Original CSS */
.custome-card-section {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

.custome-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 300px;
  /* Fixed width for cards */
}

.custome-card img {
  display: block;
  width: 100%;
  height: auto;
}

.custome-card p {
  text-align: center;
  font-size: 16px;
  color: #333;
}

.custome-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.custome-title {
  color: #ddd;
  font-weight: bold;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .custome-card-section {
    flex-wrap: wrap;
  }

  .custome-card {
    flex: 1 1 calc(50% - 40px);
    /* Two cards per row with gap */
    margin-bottom: 20px;
    /* Space between rows */
  }
}

@media (max-width: 768px) {
  .custome-card {
    flex: 1 1 calc(100% - 40px);
    /* Full width, one card per row */
  }
}
