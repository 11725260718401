/* .DM-IMG styles */
.DM-IMG {
  width: 100vw; /* Full width */
  height: auto; /* Adjust height based on content */
  object-fit: cover; /* Ensure the image covers the container */
}

/* dm-heading styles */
.dm-heading {
  text-align: center;
  font-size: 1.5rem; /* Responsive font size */
  margin: 1rem 0; /* Margin for spacing */
}

/* dm-para and dm-para-2 styles */
.dm-para, .dm-para-2 {
  text-align: center;
  font-size: 1rem; /* Responsive font size */
  padding: 0 1rem; /* Responsive padding */
}

/* Container card styles */
.container-card {
  padding: 1rem; /* Responsive padding */
}

/* dm-container styles */
.dm-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem; /* Responsive margin */
  padding: 1rem; /* Responsive padding */
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  animation: fadeInUp 0.5s ease forwards;
  opacity: 0;
}

/* Hover effect for dm-container */
.dm-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* dm-image styles */
.dm-image {
  flex: 0 0 100px; /* Adjusted size for smaller screens */
  height: 100px;
  margin: 0 1rem; /* Responsive margin */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* dm-image img styles */
.dm-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

/* dm-content styles */
.dm-content {
  flex: 1;
  max-width: 100%; /* Full width on smaller screens */
  padding: 1rem; /* Responsive padding */
  box-sizing: border-box;
}

/* dm-content h4 styles */
.dm-content h4 {
  
  margin-bottom: 0.5rem;
  color: #333;
  font-size: 1.25rem; /* Responsive font size */
}

/* dm-content p styles */
.dm-content p {
  color: #555;
  font-size: 1rem; /* Responsive font size */
}

/* Responsive adjustments for dm-container */
@media (max-width: 768px) {
  .dm-container {
    flex-direction: column; /* Stack content vertically */
    align-items: center;
    text-align: center;
  }

  .dm-image {
    margin: 0; /* Remove margin for stacking */
  }

  .dm-content {
    width: 100%; /* Full width */
    padding: 0.5rem; /* Adjust padding */
  }
  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Smaller grid items on phone view */
    gap: 10px; /* Reduce gap between grid items */
    padding: 0; /* Remove padding if needed */
  }
}

/* Keyframes for fadeInUp animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Additional section styles */
.additional-section {
  padding: 2rem 0; /* Adjusted padding */
  background-color: #f7f7f7;
}

/* additional-heading styles */
.additional-heading {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem; /* Responsive margin */
  font-size: 1.5rem; /* Responsive font size */
  color: #333;
}

/* Grid container styles */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
  gap: 20px; /* Space between grid items */
  padding: 0 1rem; /* Responsive padding */
  
}

/* Grid item styles */
.grid-item {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

/* Hover effect for grid items */
.grid-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* grid-image styles */
.grid-image img {
  width: 100%;
  height: auto; /* Adjust height to maintain aspect ratio */
}

/* grid-content styles */
.grid-content {
  padding: 1rem; /* Responsive padding */
}

/* grid-content h4 styles */
.grid-content h4 {
  margin: 0;
  font-size: 1.25rem; /* Responsive font size */
  color: #222;
}

/* grid-content p styles */
.grid-content p {
  margin: 0.5rem 0 0;
  font-size: 1rem; /* Responsive font size */
  color: #666;
}
@media (max-width: 600px) {
  .DM-IMG {
    width: 100vw; /* Ensure full width on smaller screens */
    height: 45vh; /* Increase height for better visibility on phone view */
  }

  .dm-para {
    font-size: 1rem; /* Smaller font size for better readability */
    padding: 0 0.5rem; /* Reduce padding on smaller screens */
    line-height: 1.5; /* Improve readability */
  }
  .container-card {
    margin: 0.5rem; /* Add margin for spacing between cards */
    padding: 1rem; /* Add padding around the container */
  }
  
  /* Additional padding around the container for phone view */
  .container-card-wrapper {
    padding: 1rem; /* Adjust padding around the container */
  }
}