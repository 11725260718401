/* App padding */
.App {
  padding: 2rem;
}

/* Card grid layout */
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  width: 100%;
}

/* Main card styles */
.main-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  color: black;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, background-color 0.3s;
  margin-top: 3rem;
}

.main-card:hover {
  transform: scale(1.05);
  color: white;
}

/* Main card image styles */
.main-card-image {
  width: 100%;
  height: auto;
}

/* Card content styles */
.card-content {
  padding: 16px;
  text-align: center;
}

/* Card title styles */
.card-title {
  margin: 16px 0 8px;
  font-size: 1.25rem;
  color: #333;
}

/* Card description styles */
.card-description {
  margin: 0;
  font-size: 1rem;
  color: #666;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .App {
    padding: 1rem;
  }

  .main-card {
    margin-top: 50px;
  }

  .card-title {
    font-size: 1.1rem;
  }

  .card-description {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .App {
    margin-top: -1rem;
    padding: 1.5rem;
    margin-bottom: -2rem;
  }

  .main-card {
    margin-top: 1rem;
  }

  .card-title {
    font-size: 1rem;
  }

  .card-description {
    font-size: 0.8rem;
  }

  .card-content {
    padding: 8px;
  }

}
