/* DevOps.css */

.new-page {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}

.top {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

.card {
  width: calc(20% - 20px); /* Default: 5 cards in one row */
  max-width: 280px;
  height: 360px; /* Adjust height as needed */
  perspective: 1000px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
}

.card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-back {
  transform: rotateY(180deg);
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card img {
  width: 100%;
  height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-content {
  padding: 20px;
  text-align: center;
}

.card-content h3 {
  margin-bottom: 10px;
}

.card-content p {
  color: #666;
}
.points-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap; /* Enable wrapping */
  gap: 10px; /* Gap between points */
}

.points-container .main-heading {
  width: 100%; /* Full width for heading */
  margin-bottom: 50px;
  margin-top: 20px;
}

.point {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  flex: 1 1 calc(50% - 10px); /* Adjust flex basis for 2 in a row */
  box-sizing: border-box; /* Include padding and border in width/height */
}

.point:hover {
  background-color: #e0e0e0;
}

.point h3 {
  margin-bottom: 10px;
}

.point p {
  color: #666;
}
/* Responsive Styles */
@media (max-width: 1200px) {
  .card {
    width: calc(25% - 20px); /* 4 cards in one row */
  }
}

@media (max-width: 992px) {
  .card {
    width: calc(33.333% - 20px); /* 3 cards in one row */
  }
}

@media (max-width: 768px) {
  .card {
    width: calc(50% - 20px); /* 2 cards in one row */
  }
}

@media (max-width: 480px) {
  .card {
    width: calc(100% - 20px); /* 1 card in one row */
  }
  
}
