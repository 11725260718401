/* General Styles */
h2 {
  margin-top: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
  font-size: 3rem;
  text-transform: capitalize;
  z-index: 100;
}

.left-card, #para {
  text-align: center;
  font-smooth: smooth;
  font-size: 20px;
  padding: 0 25px 50px 50px;
}

.right-card {
  padding: 20px;
  background: gray;
  color: white;
  margin: 0 10rem 3rem 10rem;
}

.img {
  width: 100vw;
  height: 150vh;
}

.heading {
  margin-top: 1rem;
  text-shadow: 4px 4px rgba(0, 0, 0, 0.3);
}

.heading-2, .heading-3 {
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
}

.insurance-para {
  text-align: center;
  padding: 0 2rem 2rem 2rem;
}

/* Custome Card Section */
.custome-card-section {
  display: flex;
  justify-content: space-around;
  margin: 20px;
  gap: 4rem;
}

.custome-card {
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 300px;
}

.custome-card img {
  display: block;
  width: 100%;
  height: auto;
}

.custome-card p {
  text-align: center;
  font-size: 16px;
  color: #333;
}

.custome-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.custome-title {
  margin-top: .75rem;
  color: #ddd;
  font-weight: bold;
  text-align: center;
}

/* Insurance Card Section */
.insurance-card-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.insurance-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.insurance-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Media Queries */
@media (max-width: 1200px) {
  .custome-card-section {
    flex-wrap: wrap;
  }

  .custome-card {
    flex: 1 1 calc(50% - 40px);
    margin-bottom: 20px;
  }

  .right-card {
    margin: 0 5rem 3rem 5rem;
  }
}

@media (max-width: 768px) {
  h2 {
    font-size: 1.5rem;
  }

  .custome-card {
    flex: 1 1 calc(100% - 40px);
  }

  .right-card {
    margin: 0 2rem 3rem 2rem;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.2rem;
    text-decoration: underline ;
  }

  .left-card, #para {
    font-size: 16px;
    padding: 0 10px 30px 10px;
  }

  .right-card {
    margin: 0 1rem 2rem 1rem;
  }

  .custome-card-section {
    gap: 1rem;
  }
}
